<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-button class="btn-icon" variant="flat" style="padding: 0">
          <h4>Resumo por franqueado</h4>
        </b-button>
        <b-card-text class="mb-1">
          <b-badge variant="primary" class="mr-1">
            <span>Arraste para o lado</span>
            <feather-icon icon="ChevronsRightIcon" class="ml-25" />
          </b-badge>
        </b-card-text>
      </b-card-header>
      <b-table
        :items="items"
        :fields="tableColumns"
        :busy="loading"
        :tbody-tr-class="rowClass"
        :sticky-header="stickyHeaderHeight"
        hover
        responsive
        no-border-collapse
        show-empty
        striped
        :class="[
          'position-relative text-nowrap mb-0',
          detectSafariBrowser ? 'pb-1 scroll-fixed' : '',
        ]"
        primary-key="id"
        empty-text="Não foram encontrados dados de franqueados para esta busca"
        @row-clicked="rowClicked"
        @sort-changed="sort"
        :sort-by="sortField"
        :sort-compare="sortCompare"
      >
        <template #thead-top="data">
          <b-tr class="top-sticky">
            <b-th colspan="3" class="header-spacing"></b-th>
            <b-th colspan="6" class="header-spacing text-center bg-info"
              >Entrevista</b-th
            >
            <b-th
              colspan="3"
              class="header-spacing text-center bg-danger bg-lighten-2"
              >Recomendações</b-th
            >
            <b-th
              colspan="4"
              class="header-spacing text-center bg-green-2 bg-lighten-2"
              >Eficiência Entrevista</b-th
            >
            <b-th
              colspan="5"
              class="header-spacing text-center bg-blue bg-lighten-2 text-white"
              >Momento X</b-th
            >
            <b-th
              colspan="5"
              class="header-spacing text-center bg-primary bg-lighten-2"
              >Parcerias</b-th
            >
            <b-th
              colspan="3"
              class="header-spacing text-center bg-secondary bg-lighten-2"
              >Assinaturas</b-th
            >
            <b-th colspan="5" class="text-center bg-success bg-lighten-2"
              >Resumos</b-th
            >
          </b-tr>
        </template>
        <!-- Column: Estrutura -->
        <template #cell(leader_franchise_name)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="data.item.visible"
              @change="changeVisibleFranchise(data.item.leader_franchise_name)"
              inline
            >
              <span
                v-if="isHunter && data.item.leader_franchise_name !== 'Total'"
              >
                Estrutura PNH
              </span>
              <span v-else>
                {{ data.item.leader_franchise_name }}
              </span>
            </b-form-checkbox>
          </div>
        </template>
        <!-- Column: Leads - Quente -->
        <template #cell(hot_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.hot_leads_quantity }}</span
            >
          </div>
        </template>
        <!-- Column: Quantidade de PNs -->
        <template #cell(consultants_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.consultants_quantity }}</span
            >
            <b-tooltip
              target="consultants_quantity"
              title="Quantidade de PNs"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: EA -->
        <template #cell(scheduled_interview_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.scheduled_interview_quantity }}</span
            >
            <b-tooltip
              target="scheduled_interview_quantity"
              title="Entrevista agendada"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PEF -->
        <template #cell(pre_interviews_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.pre_interviews_quantity }}</span
            >
            <b-tooltip
              target="pre_interviews_quantity"
              title="Pré-entrevista feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EF -->
        <template #cell(done_interview_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.done_interview_quantity }}</span
            >
            <b-tooltip
              target="done_interview_quantity"
              title="Entrevista feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EP -->
        <template #cell(interview_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{
                data.item.interview_contract_quantity -
                data.item.negotiation_interview_contract_quantity
              }}</span
            >
            <b-tooltip
              target="interview_contract_quantity"
              title="Entrevista paga"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: NEA -->
        <template #cell(scheduled_negotiation_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.scheduled_negotiation_quantity }}</span
            >
            <b-tooltip
              target="scheduled_negotiation_quantity"
              title="EPs em negociação agendadas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: NEP -->
        <template #cell(negotiation_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.negotiation_interview_contract_quantity }}</span
            >
            <b-tooltip
              target="negotiation_positive_feedbacks_quantity"
              title="EPs em negociação paga"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EA/EF -->
        <template #cell(ea_ef)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.ea_ef =
                  (data.item.scheduled_interview_quantity
                    ? data.item.done_interview_quantity /
                      data.item.scheduled_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="ea_ef"
              title="Eficiência de entrevistas agendadas para entrevistas feitas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EF/EP -->
        <template #cell(ef_ep)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.ef_ep =
                  (data.item.done_interview_quantity
                    ? data.item.interview_contract_quantity /
                      data.item.done_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="ef_ep"
              title="Eficiência de entrevistas feitas para entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EA/EP -->
        <template #cell(ea_ep)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.ea_ep =
                  (data.item.scheduled_interview_quantity
                    ? data.item.interview_contract_quantity /
                      data.item.scheduled_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="ea_ep"
              title="Eficiência de entrevistas agendadas para entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$/EP -->
        <template #cell(interview_contract_average)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.interview_contract_average = medEp(data))
                  | toCurrency
              }}
            </span>
            <b-tooltip
              target="interview_contract_average"
              title="Valor médio de entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: REC CL -->
        <template #cell(client_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.client_leads_quantity }}</span
            >
            <b-tooltip
              target="client_leads_quantity"
              title="Recomendações de clientes"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: REC PN -->
        <template #cell(consultant_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.consultant_leads_quantity }}</span
            >
            <b-tooltip
              target="consultant_leads_quantity"
              title="Recomendações de PNs"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: REC/EF -->
        <template #cell(rec_ef)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{
                (data.item.rec_ef = data.item.done_interview_quantity
                  ? (data.item.consultant_leads_quantity +
                      data.item.client_leads_quantity) /
                    data.item.done_interview_quantity
                  : 0) | toDecimal
              }}</span
            >
            <b-tooltip
              target="rec_ef"
              title="Média de Recomendações por Entrevista Feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: MXA -->
        <template #cell(scheduled_x_moment_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.scheduled_x_moment_quantity }}</span
            >
          </div>
          <b-tooltip
            target="scheduled_x_moment_quantity"
            title="Momento X agendado"
            placement="top"
            boundary="document"
          ></b-tooltip>
        </template>
        <!-- Column: MXF -->
        <template #cell(done_x_moment_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.done_x_moment_quantity }}</span
            >
            <b-tooltip
              target="done_x_moment_quantity"
              title="Momento X feito"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXC -->
        <template #cell(x_moment_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.x_moment_positive_feedbacks_quantity }}</span
            >
            <b-tooltip
              target="x_moment_positive_feedbacks_quantity"
              title="Momento X com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXA/MXF -->
        <template #cell(mxa_mxf)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.mxa_mxf =
                  (data.item.scheduled_x_moment_quantity &&
                  data.item.done_x_moment_quantity
                    ? data.item.done_x_moment_quantity /
                      data.item.scheduled_x_moment_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="mxa_mxf"
              title="Eficiência de Momento X agendado para Momento X feito"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXF/MXC -->
        <template #cell(mxf_mxc)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.mxf_mxc =
                  (data.item.scheduled_x_moment_quantity
                    ? data.item.x_moment_positive_feedbacks_quantity /
                      data.item.done_x_moment_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="mxf_mxc"
              title="Eficiência de Momento X feita para Momento X com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PA -->
        <template #cell(scheduled_partnership_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.scheduled_partnership_quantity }}</span
            >
            <b-tooltip
              target="scheduled_partnership_quantity"
              title="Parceria agendada"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PF -->
        <template #cell(done_partnership_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.done_partnership_quantity }}</span
            >
            <b-tooltip
              target="done_partnership_quantity"
              title="Parceria feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PC -->
        <template #cell(partnership_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.partnership_positive_feedbacks_quantity }}</span
            >
            <b-tooltip
              target="partnership_positive_feedbacks_quantity"
              title="Parceria com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PA/PF -->
        <template #cell(pa_pf)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.pa_pf =
                  (data.item.scheduled_partnership_quantity
                    ? data.item.done_partnership_quantity /
                      data.item.scheduled_partnership_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="pa_pf"
              title="Eficiência de parceria agendada para parceria feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PF/PC -->
        <template #cell(pf_pc)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{
                (data.item.pf_pc =
                  (data.item.done_partnership_quantity
                    ? data.item.partnership_positive_feedbacks_quantity /
                      data.item.done_partnership_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              target="pf_pc"
              title="Eficiência de Parceria feita para parceria com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: Suporte -->
        <template #cell(support_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ data.item.support_contract_quantity }}
            </span>
            <b-tooltip
              target="support_contract_quantity"
              title="Suportes."
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: Acompanhamentos -->
        <template #cell(signature_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ data.item.signature_contract_quantity }}
            </span>
            <b-tooltip
              target="signature_contract_quantity"
              title="Soma dos acompanhamentos: básico, essencial e elite."
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: Renovações -->
        <template #cell(renewal_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ data.item.renewal_contract_quantity }}
            </span>
            <b-tooltip
              target="renewal_contract_quantity"
              title="Renovações."
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de EP -->
        <template #cell(interview_contract_amount)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ data.item.interview_contract_amount | toCurrency }}
            </span>
            <b-tooltip
              target="interview_contract_amount"
              title="Faturamento de Entrevistas Pagas"
              placement="top"
              boundary="document"
            >
            </b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de Assinatura -->
        <template #cell(subscription_contract_amount)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{ data.item.subscription_contract_amount | toCurrency }}</span
            >
            <b-tooltip
              target="subscription_contract_amount"
              title="Faturamento de Assinatura"
              placement="top"
              boundary="document"
            >
            </b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de Soluções -->
        <template #cell(other_financial_solutions_contract_amount)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
              >{{
                data.item.other_financial_solutions_contract_amount | toCurrency
              }}</span
            >
            <b-tooltip
              target="other_financial_solutions_contract_amount"
              title="Faturamento de Soluções"
              placement="top"
              boundary="document"
            >
            </b-tooltip>
          </div>
        </template>
        <!-- Column: R$ Total  -->
        <template #cell(total_contract_amount)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ data.item.total_contract_amount | toCurrency }}
            </span>

            <b-tooltip
              target="total_contract_amount"
              title="Faturamento total"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: LTV/CL  -->
        <template #cell(ltv_cl)="data">
          <div class="text-wrap text-center">
            <span
              v-if="data.item.visible"
              class="align-text-top text-capitalize"
            >
              {{ (data.item.ltv_cl = ltvCl(data)) | toCurrency }}
            </span>
            <b-tooltip
              target="ltv_cl"
              title="Faturamento total / Número de EPs"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BTooltip,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BPopover,
  BTh,
  BTr,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import { mapGetters } from "vuex";
import * as accountTypes from "@/modules/account/store/types";
import _ from "lodash";
import sortCompare from "@/helpers/kpiSorting";
import useAppConfig from "@core/app-config/useAppConfig";
import { detectSafariBrowser } from "@/mixins/detectSafariBrowser";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BTooltip,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormCheckbox,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BPopover,
    BTh,
    BTr,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  props: {
    totalizers: {
      type: Array,
      required: true,
    },
  },
  mixins: [detectSafariBrowser],
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          key: "leader_franchise_name",
          label: "Franqueados",
          stickyColumn: true,
        },
        {
          key: "consultants_quantity",
          label: "PNs",
          sortable: true,
        },
        {
          key: "hot_leads_quantity",
          label: "Leads - Quente",
          thClass: [
            "header-spacing",
            "bg-danger",
            "text-white",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "scheduled_interview_quantity",
          label: "EA",
          thAttr: { id: "scheduled_interview_quantity" },
          thClass: ["bg-info", "position-sticky"],
          sortable: true,
        },
        {
          key: "pre_interviews_quantity",
          label: "PEF",
          thAttr: { id: "pre_interviews_quantity" },
          thClass: ["bg-info", "bg-lighten-3", "position-sticky"],
          sortable: true,
        },
        {
          key: "done_interview_quantity",
          label: "EF",
          thAttr: { id: "done_interview_quantity" },
          thClass: ["bg-info", "position-sticky"],
          sortable: true,
        },
        {
          key: "interview_contract_quantity",
          label: "EP",
          thAttr: { id: "interview_contract_quantity" },
          thClass: ["bg-info", "bg-lighten-3", "position-sticky"],
          sortable: true,
        },
        {
          key: "scheduled_negotiation_quantity",
          label: "NEA",
          thAttr: { id: "scheduled_negotiation_quantity" },
          thClass: ["bg-info", "position-sticky"],
          sortable: true,
        },
        {
          key: "negotiation_positive_feedbacks_quantity",
          label: "NEP",
          thAttr: { id: "negotiation_positive_feedbacks_quantity" },
          thClass: [
            "header-spacing",
            "bg-info",
            "bg-lighten-3",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "consultant_leads_quantity",
          label: "REC PN",
          thAttr: { id: "consultant_leads_quantity" },
          thClass: ["bg-danger", "bg-lighten-5", "position-sticky"],
          sortable: true,
        },
        {
          key: "client_leads_quantity",
          label: "REC CL",
          thAttr: { id: "client_leads_quantity" },
          thClass: ["bg-danger", "bg-lighten-2", "position-sticky"],
          sortable: true,
        },
        {
          key: "rec_ef",
          label: "REC/EF",
          thAttr: { id: "rec_ef" },
          thClass: [
            "header-spacing",
            "bg-danger",
            "bg-lighten-5",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "ea_ef",
          label: "EA/EF",
          thAttr: { id: "ea_ef" },
          thClass: ["bg-green-2", "bg-lighten-2", "position-sticky"],
          sortable: true,
        },
        {
          key: "ef_ep",
          label: "EF/EP",
          thAttr: { id: "ef_ep" },
          thClass: ["bg-green-2", "bg-lighten-5", "position-sticky"],
          sortable: true,
        },
        {
          key: "ea_ep",
          label: "EA/EP",
          thAttr: { id: "ea_ep" },
          thClass: ["bg-green-2", "bg-lighten-2", "position-sticky"],
          sortable: true,
        },
        {
          key: "interview_contract_average",
          label: "R$/EP",
          thAttr: { id: "interview_contract_average" },
          thClass: [
            "header-spacing",
            "bg-green-2",
            "bg-lighten-5",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "scheduled_x_moment_quantity",
          label: "MXA",
          thAttr: { id: "scheduled_x_moment_quantity" },
          thClass: ["bg-blue", "bg-lighten-2", "text-white", "position-sticky"],
          sortable: true,
        },
        {
          key: "done_x_moment_quantity",
          label: "MXF",
          thAttr: { id: "done_x_moment_quantity" },
          thClass: ["bg-blue", "bg-lighten-5", "text-white", "position-sticky"],
          sortable: true,
        },
        {
          key: "x_moment_positive_feedbacks_quantity",
          label: "MXC",
          thAttr: { id: "x_moment_positive_feedbacks_quantity" },
          thClass: [
            "column-spacing",
            "bg-blue",
            "bg-lighten-2",
            "text-white",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "mxa_mxf",
          label: "MXA/MXF",
          thAttr: { id: "mxa_mxf" },
          thClass: ["bg-blue", "bg-lighten-5", "text-white", "position-sticky"],
          sortable: true,
        },
        {
          key: "mxf_mxc",
          label: "MXF/MXC",
          thAttr: { id: "mxf_mxc" },
          thClass: [
            "header-spacing",
            "bg-blue",
            "bg-lighten-2",
            "text-white",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "scheduled_partnership_quantity",
          label: "PA",
          thAttr: { id: "scheduled_partnership_quantity" },
          thClass: ["bg-primary", "bg-lighten-2", "position-sticky"],
          sortable: true,
        },
        {
          key: "done_partnership_quantity",
          label: "PF",
          thAttr: { id: "done_partnership_quantity" },
          thClass: ["bg-primary", "bg-lighten-5", "position-sticky"],
          sortable: true,
        },
        {
          key: "partnership_positive_feedbacks_quantity",
          label: "PC",
          thAttr: { id: "partnership_positive_feedbacks_quantity" },
          thClass: [
            "column-spacing",
            "bg-primary",
            "bg-lighten-2",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "pa_pf",
          label: "PA/PF",
          thAttr: { id: "pa_pf" },
          thClass: ["bg-primary", "bg-lighten-5", "position-sticky"],
          sortable: true,
        },
        {
          key: "pf_pc",
          label: "PF/PC",
          thAttr: { id: "pf_pc" },
          thClass: [
            "header-spacing",
            "bg-primary",
            "bg-lighten-2",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "support_contract_quantity",
          label: "Suporte",
          thAttr: { id: "support_contract_quantity" },
          thClass: ["bg-secondary", "bg-lighten-2", "position-sticky"],
          sortable: true,
        },
        {
          key: "signature_contract_quantity",
          label: "Acompanhamentos",
          thAttr: { id: "signature_contract_quantity" },
          thClass: [
            "bg-secondary",
            "bg-lighten-5",
            "header-spacing",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "renewal_contract_quantity",
          label: "Renovações",
          thAttr: { id: "renewal_contract_quantity" },
          thClass: [
            "header-spacing",
            "bg-secondary",
            "bg-lighten-2",
            "position-sticky",
          ],
          sortable: true,
        },
        {
          key: "interview_contract_amount",
          label: "R$ de EP",
          thAttr: { id: "interview_contract_amount" },
          thClass: ["bg-success", "bg-lighten-1", "position-sticky"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "subscription_contract_amount",
          label: "R$ de Assinatura",
          thAttr: { id: "subscription_contract_amount" },
          thClass: ["bg-success", "bg-lighten-4", "position-sticky"],
          tdClass: ["pl-0", "pr-4", ,],
          sortable: true,
        },
        {
          key: "other_financial_solutions_contract_amount",
          label: "R$ de Soluções",
          thAttr: { id: "other_financial_solutions_contract_amount" },
          thClass: ["bg-success", "bg-lighten-1", "position-sticky"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "total_contract_amount",
          label: "R$ Total",
          thAttr: { id: "total_contract_amount" },
          thClass: ["bg-success", "bg-lighten-4", "position-sticky"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "ltv_cl",
          label: "LTV/CL",
          thAttr: { id: "ltv_cl" },
          thClass: ["bg-success", "bg-lighten-1", "position-sticky"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
      ],
      items: [],
      stickyHeaderHeight: "300px",
      sortField: null,
    };
  },
  computed: {
    ...mapGetters({
      isHunter: accountTypes.IS_HUNTER,
    }),

    toCurrency() {
      return (value) => this.$options.filters.toCurrency(value);
    },
  },
  watch: {
    totalizers() {
      this.buildFranchises();
    },
  },
  mounted() {
    Vue.filter("toCurrency", function (value) {
      if (typeof value !== "number") {
        value = parseFloat(value);
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });
    this.buildFranchises();
  },
  methods: {
    sortCompare,
    buildFranchises() {
      this.items = _.map(this.totalizers, (franchise) => {
        return {
          ...franchise,
          visible: true,
        };
      });
      this.calculateTotal();
    },
    ltvCl(data) {
      return data?.item?.interview_contract_quantity
        ? (data?.item?.interview_contract_amount +
            data?.item?.subscription_contract_amount +
            data?.item?.other_financial_solutions_contract_amount) /
            data?.item?.interview_contract_quantity
        : 0;
    },
    medEp(data) {
      return data?.item?.interview_contract_quantity
        ? data.item.interview_contract_amount /
            data.item.interview_contract_quantity
        : 0;
    },
    calculateTotal() {
      _.remove(this.items, {
        leader_franchise_name: "Total",
      });
      const filteredItems = _.filter(this.items, { visible: true });
      var total = _(filteredItems)
        .groupBy("name")
        .map((objs, key) => ({
          leader_franchise_name: key,
          name: "Total",
          hot_leads_quantity: _.sumBy(objs, "hot_leads_quantity"),
          scheduled_interview_quantity: _.sumBy(
            objs,
            "scheduled_interview_quantity"
          ),
          pre_interviews_quantity: _.sumBy(objs, "pre_interviews_quantity"),
          interview_contract_quantity: _.sumBy(
            objs,
            "interview_contract_quantity"
          ),
          negotiation_interview_contract_quantity: _.sumBy(
            objs,
            "negotiation_interview_contract_quantity"
          ),
          done_interview_quantity: _.sumBy(objs, "done_interview_quantity"),
          client_leads_quantity: _.sumBy(objs, "client_leads_quantity"),
          consultant_leads_quantity: _.sumBy(objs, "consultant_leads_quantity"),
          scheduled_x_moment_quantity: _.sumBy(
            objs,
            "scheduled_x_moment_quantity"
          ),
          done_x_moment_quantity: _.sumBy(objs, "done_x_moment_quantity"),
          x_moment_positive_feedbacks_quantity: _.sumBy(
            objs,
            "x_moment_positive_feedbacks_quantity"
          ),
          scheduled_partnership_quantity: _.sumBy(
            objs,
            "scheduled_partnership_quantity"
          ),
          done_partnership_quantity: _.sumBy(objs, "done_partnership_quantity"),
          partnership_positive_feedbacks_quantity: _.sumBy(
            objs,
            "partnership_positive_feedbacks_quantity"
          ),
          interview_contract_amount: _.sumBy(objs, "interview_contract_amount"),
          subscription_contract_amount: _.sumBy(
            objs,
            "subscription_contract_amount"
          ),
          other_financial_solutions_contract_amount: _.sumBy(
            objs,
            "other_financial_solutions_contract_amount"
          ),
          total_contract_amount: _.sumBy(objs, "total_contract_amount"),
          support_contract_quantity: _.sumBy(objs, "support_contract_quantity"),
          signature_contract_quantity: _.sumBy(
            objs,
            "signature_contract_quantity"
          ),
          scheduled_negotiation_quantity: _.sumBy(
            objs,
            "scheduled_negotiation_quantity"
          ),
          negotiation_positive_feedbacks_quantity: _.sumBy(
            objs,
            "negotiation_positive_feedbacks_quantity"
          ),
          renewal_contract_quantity: _.sumBy(objs, "renewal_contract_quantity"),
          consultants_quantity: _.sumBy(objs, "consultants_quantity"),
          visible: true,
        }))
        .value();
      this.items.push(...total);
      let height = 300;
      if (this.items.length > 5 && this.items.length <= 10) {
        height = 500;
      } else if (this.items.length > 10) {
        height = 700;
      }
      this.stickyHeaderHeight = `${height}px`;
    },
    rowClass(item, type) {
      let colorClass = "";
      if (!item || type !== "row") {
        return;
      }
      // eslint-disable-next-line consistent-return
      if (item.leader_franchise_name === "Total") {
        if (this.skin === "dark") {
          colorClass = "total-dark-color";
        } else if (this.skin === "light") {
          colorClass = "table-primary";
        }
        return colorClass;
      }
    },
    changeVisibleFranchise(leader_franchise_name) {
      const index = _.findIndex(this.items, { leader_franchise_name });
      if (leader_franchise_name === "Total") {
        _.forEach(this.items, (franchise) => {
          franchise.visible = !this.items[index].visible;
          this.$emit("changeVisibleFranchise", {
            name: franchise.leader_franchise_name,
            visible: franchise.visible,
          });
        });
      } else if (this.items[index] != undefined) {
        this.items[index].visible = !this.items[index].visible;
        this.$emit("changeVisibleFranchise", {
          name: leader_franchise_name,
          visible: this.items[index]?.visible,
        });
      }
      this.calculateTotal();
    },
    rowClicked(item, index) {
      this.$emit("rowClicked", item);
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.sortField = null;
      } else {
        this.sortField = event.sortBy;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.popover.b-popover {
  max-width: 500px !important;
  width: max-content !important;
}

::v-deep .table {
  thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 3 !important;
  }
}

.card .table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
}
</style>
<style lang="scss">
.header-spacing {
  border-right: 15px solid #ebe9f1;
}

.column-spacing {
  border-right: 10px solid #ebe9f1;
}

th {
  text-align: center;
}

[aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Earrows%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-257.000000, -561.000000)' stroke='%236e6b7b' stroke-width='2'%3E%3Cg id='arrows' transform='translate(258.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Eactive-up%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-197.000000, -561.000000)' stroke-width='2'%3E%3Cg id='active-up' transform='translate(198.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)' stroke='%236e6b7b'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

[aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Eactive-up%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-197.000000, -561.000000)' stroke-width='2'%3E%3Cg id='active-up' transform='translate(198.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up' stroke='%236e6b7b'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

.total-dark-color {
  background-color: #ff7f50;

  &:hover {
    background-color: rgba(255, 127, 80, 0.8) !important;
  }
}

.dark-layout .table tr:last-child {
  td {
    color: #fff !important;
  }
}
</style>
