<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-button
          class="btn-icon"
          variant="flat"
          style="padding: 0"
          @click="visible = !visible"
        >
          <h4 :class="{ 'mb-1': !visible }">
            <feather-icon v-if="visible" size="20" icon="ChevronDownIcon" />
            <feather-icon v-else size="20" icon="ChevronUpIcon" />
            <span v-if="isHunter"> Estrutura PNH </span>
            <span v-else> Franqueado {{ franchiseName }} </span>
          </h4>
        </b-button>
        <b-card-text v-if="visible" class="mb-1">
          <b-badge variant="primary" class="mr-1">
            <span>Arraste para o lado</span>
            <feather-icon icon="ChevronsRightIcon" class="ml-25" />
          </b-badge>
        </b-card-text>
      </b-card-header>
      <b-table
        v-if="visible"
        :items="items"
        :fields="tableColumns"
        :busy="loading"
        :tbody-tr-class="rowClass"
        :sticky-header="stickyHeaderHeight"
        hover
        responsive
        no-border-collapse
        show-empty
        striped
        :class="[
          'position-relative text-nowrap mb-0',
          detectSafariBrowser ? 'pb-1 scroll-fixed' : '',
        ]"
        primary-key="id"
        empty-text="Não foram encontrados dados de PNs para esta busca"
        @sort-changed="sort"
        :sort-by="sortField"
        :sort-compare="sortCompare"
      >
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="2" class="header-spacing"></b-th>
            <b-th colspan="6" class="header-spacing text-center bg-info"
              >Entrevista</b-th
            >
            <b-th
              colspan="3"
              class="header-spacing text-center bg-danger bg-lighten-2"
              >Recomendações</b-th
            >
            <b-th
              colspan="4"
              class="header-spacing text-center bg-green-2 bg-lighten-2"
              >Eficiência Entrevista</b-th
            >
            <b-th
              colspan="5"
              class="header-spacing text-center bg-blue bg-lighten-2 text-white"
              >Momento X</b-th
            >
            <b-th
              colspan="5"
              class="header-spacing text-center bg-primary bg-lighten-2"
              >Parcerias</b-th
            >
            <b-th
              colspan="3"
              class="header-spacing text-center bg-secondary bg-lighten-2"
              >Assinaturas</b-th
            >
            <b-th colspan="5" class="text-center bg-success bg-lighten-2"
              >Resumos</b-th
            >
          </b-tr>
        </template>

        <!-- Column: Estrutura -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <!-- Column: Leads - Quente -->
        <template #cell(hot_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.hot_leads_quantity
            }}</span>
          </div>
        </template>

        <!-- Column: EA -->
        <template #cell(scheduled_interview_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.scheduled_interview_quantity
            }}</span>
            <b-tooltip
              :target="'scheduled_interview_quantity_' + franchiseName"
              title="Entrevista agendada"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PEF -->
        <template #cell(pre_interviews_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.pre_interviews_quantity
            }}</span>
            <b-tooltip
              :target="'pre_interviews_quantity_' + franchiseName"
              title="Pré-entrevista feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EF -->
        <template #cell(done_interview_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.done_interview_quantity
            }}</span>
            <b-tooltip
              :target="'done_interview_quantity_' + franchiseName"
              title="Entrevista feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EP -->
        <template #cell(interview_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.interview_contract_quantity -
              data.item.negotiation_interview_contract_quantity
            }}</span>
            <b-tooltip
              :target="'interview_contract_quantity_' + franchiseName"
              title="Entrevista paga"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: NEA -->
        <template #cell(scheduled_negotiation_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.scheduled_negotiation_quantity
            }}</span>
            <b-tooltip
              :target="'scheduled_negotiation_quantity_' + franchiseName"
              title="EPs em negociação agendadas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: NEP -->
        <template #cell(negotiation_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.negotiation_interview_contract_quantity
            }}</span>
            <b-tooltip
              :target="
                'negotiation_positive_feedbacks_quantity_' + franchiseName
              "
              title="EPs em negociação paga"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EA/EF -->
        <template #cell(ea_ef)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.ea_ef =
                  (data.item.scheduled_interview_quantity
                    ? data.item.done_interview_quantity /
                      data.item.scheduled_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'ea_ef_' + franchiseName"
              title="Eficiência de entrevistas agendadas para entrevistas feitas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EF/EP -->
        <template #cell(ef_ep)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.ef_ep =
                  (data.item.done_interview_quantity
                    ? data.item.interview_contract_quantity /
                      data.item.done_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'ef_ep_' + franchiseName"
              title="Eficiência de entrevistas feitas para entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: EA/EP -->
        <template #cell(ea_ep)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.ea_ep =
                  (data.item.scheduled_interview_quantity
                    ? data.item.interview_contract_quantity /
                      data.item.scheduled_interview_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'ea_ep_' + franchiseName"
              title="Eficiência de entrevistas agendadas para entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$/EP -->
        <template #cell(client_interview_contract_average)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.client_interview_contract_average = medEp(data))
                  | toCurrency
              }}
            </span>
            <b-tooltip
              :target="'client_interview_contract_average_' + franchiseName"
              title="Valor médio de entrevistas pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: REC CL -->
        <template #cell(client_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.client_leads_quantity
            }}</span>
            <b-tooltip
              :target="'client_leads_quantity_' + franchiseName"
              title="Recomendações de clientes"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: REC PN -->
        <template #cell(consultant_leads_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.consultant_leads_quantity
            }}</span>
            <b-tooltip
              :target="'consultant_leads_quantity_' + franchiseName"
              title="Recomendações de PNs"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: REC/EF -->
        <template #cell(rec_ef)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              (data.item.rec_ef = data.item.done_interview_quantity
                ? (data.item.consultant_leads_quantity +
                    data.item.client_leads_quantity) /
                  data.item.done_interview_quantity
                : 0) | toDecimal
            }}</span>
            <b-tooltip
              :target="'rec_ef_' + franchiseName"
              title="Média de Recomendações por Entrevista Feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: MXA -->
        <template #cell(scheduled_x_moment_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.scheduled_x_moment_quantity
            }}</span>
            <b-tooltip
              :target="'scheduled_x_moment_quantity_' + franchiseName"
              title="Momento X agendado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXF -->
        <template #cell(done_x_moment_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.done_x_moment_quantity
            }}</span>
            <b-tooltip
              :target="'done_x_moment_quantity_' + franchiseName"
              title="Momento X feito"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXC -->
        <template #cell(x_moment_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.x_moment_positive_feedbacks_quantity
            }}</span>
            <b-tooltip
              :target="'x_moment_positive_feedbacks_quantity_' + franchiseName"
              title="Momento X com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXA/MXF -->
        <template #cell(mxa_mxf)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.mxa_mxf =
                  (data.item.scheduled_x_moment_quantity &&
                  data.item.done_x_moment_quantity
                    ? data.item.done_x_moment_quantity /
                      data.item.scheduled_x_moment_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'mxa_mxf_' + franchiseName"
              title="Eficiência de Momento X agendado para Momento X feito"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: MXF/MXC -->
        <template #cell(mxf_mxc)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.mxf_mxc =
                  (data.item.scheduled_x_moment_quantity
                    ? data.item.x_moment_positive_feedbacks_quantity /
                      data.item.done_x_moment_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'mxf_mxc_' + franchiseName"
              title="Eficiência de Momento X feita para Momento X com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PA -->
        <template #cell(scheduled_partnership_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{ data.item.scheduled_partnership_quantity }}</span
            >
            <b-tooltip
              :target="'scheduled_partnership_quantity_' + franchiseName"
              title="Parceria agendada"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PF -->
        <template #cell(done_partnership_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.done_partnership_quantity
            }}</span>
            <b-tooltip
              :target="'done_partnership_quantity_' + franchiseName"
              title="Parceria feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PC -->
        <template #cell(partnership_positive_feedbacks_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.partnership_positive_feedbacks_quantity
            }}</span>
            <b-tooltip
              :target="
                'partnership_positive_feedbacks_quantity_' + franchiseName
              "
              title="Parceria com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PA/PF -->
        <template #cell(pa_pf)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.pa_pf =
                  (data.item.scheduled_partnership_quantity
                    ? data.item.done_partnership_quantity /
                      data.item.scheduled_partnership_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'pa_pf_' + franchiseName"
              title="Eficiência de parceria agendada para parceria feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PF/PC -->
        <template #cell(pf_pc)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{
                (data.item.pf_pc =
                  (data.item.done_partnership_quantity
                    ? data.item.partnership_positive_feedbacks_quantity /
                      data.item.done_partnership_quantity
                    : 0) * 100) | toPercentage
              }}
            </span>
            <b-tooltip
              :target="'pf_pc_' + franchiseName"
              title="Eficiência de Parceria feita para parceria com contrato fechado"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: Suporte -->
        <template #cell(support_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.support_contract_quantity
            }}</span>
            <b-tooltip
              :target="'support_contract_quantity_' + franchiseName"
              title="Suportes"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: Acompanhamentos -->
        <template #cell(signature_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.signature_contract_quantity
            }}</span>
            <b-tooltip
              :target="'signature_contract_quantity_' + franchiseName"
              title="Soma dos acompanhamentos: básico, essencial e elite."
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: Renovações -->
        <template #cell(renewal_contract_quantity)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.renewal_contract_quantity
            }}</span>
            <b-tooltip
              :target="'renewal_contract_quantity_' + franchiseName"
              title="Renovações"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de EP -->
        <template #cell(interview_contract_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.interview_contract_amount | toCurrency
            }}</span>
            <b-tooltip
              :target="'interview_contract_amount_' + franchiseName"
              title="Faturamento de Entrevistas Pagas"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de Assinatura -->
        <template #cell(subscription_contract_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.subscription_contract_amount | toCurrency
            }}</span>
            <b-tooltip
              :target="'subscription_contract_amount_' + franchiseName"
              title="Faturamento de Assinatura"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$ de Soluções -->
        <template #cell(other_financial_solutions_contract_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.other_financial_solutions_contract_amount | toCurrency
            }}</span>
            <b-tooltip
              :target="
                'other_financial_solutions_contract_amount_' + franchiseName
              "
              title="Faturamento de Soluções"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: R$ Total  -->
        <template #cell(total_contract_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{ data.item.total_contract_amount | toCurrency }}
            </span>
            <b-tooltip
              :target="'total_contract_amount_' + franchiseName"
              title="Faturamento total"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>

        <!-- Column: LTV/CL  -->
        <template #cell(ltv_cl)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">
              {{ (data.item.ltv_cl = ltvCl(data)) | toCurrency }}
            </span>
            <b-tooltip
              :target="'ltv_cl_' + franchiseName"
              title="Faturamento total / Número de EPs"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BTooltip,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BPopover,
  BTh,
  BTr,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import { mapGetters } from "vuex";
import * as accountTypes from "@/modules/account/store/types";
import Button from "@/views/components/button/Button.vue";
import sortCompare from "@/helpers/kpiSorting";
import useAppConfig from "@core/app-config/useAppConfig";
import { detectSafariBrowser } from "@/mixins/detectSafariBrowser";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BTooltip,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    Button,
    BPopover,
    BCardText,
    BTh,
    BTr,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  props: {
    franchiseName: {
      type: String,
      required: true,
    },
    franchiseData: {
      type: Array,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  mixins: [detectSafariBrowser],
  data() {
    return {
      loading: false,
      tableColumns: [],
      visible: false,
      stickyHeaderHeight: "300px",
      items: [],
      sortField: undefined,
    };
  },
  computed: {
    ...mapGetters({
      isHunter: accountTypes.IS_HUNTER,
    }),
    toCurrency() {
      return (value) => this.$options.filters.toCurrency(value);
    },
  },
  watch: {
    franchiseData() {
      this.buildItems();
    },
    total() {
      this.buildItems();
    },
    items() {
      let height = 300;
      if (this.items.length > 5 && this.items.length <= 10) {
        height = 500;
      } else if (this.items.length > 10) {
        height = 700;
      }
      this.stickyHeaderHeight = `${height}px`;
    },
  },
  mounted() {
    this.buildItems();
  },
  methods: {
    sortCompare,
    ltvCl(data) {
      return data?.item?.interview_contract_quantity
        ? (data?.item?.interview_contract_amount +
            data?.item?.subscription_contract_amount +
            data?.item?.other_financial_solutions_contract_amount) /
            data?.item?.interview_contract_quantity
        : 0;
    },
    medEp(data) {
      return data?.item?.interview_contract_quantity
        ? data.item.interview_contract_amount /
            data.item.interview_contract_quantity
        : 0;
    },
    buildItems() {
      this.items = [...this.franchiseData];
      this.tableColumns = [
        { key: "name", label: "Consultor", stickyColumn: true },
        {
          key: "hot_leads_quantity",
          label: "Leads - Quente",
          thClass: ["header-spacing", "bg-danger", "text-white"],
          sortable: true,
        },
        {
          key: "scheduled_interview_quantity",
          label: "EA",
          thAttr: { id: "scheduled_interview_quantity_" + this.franchiseName },
          thClass: ["bg-info"],
          sortable: true,
        },
        {
          key: "pre_interviews_quantity",
          label: "PEF",
          thAttr: { id: "pre_interviews_quantity_" + this.franchiseName },
          thClass: ["bg-info", "bg-lighten-3"],
          sortable: true,
        },
        {
          key: "done_interview_quantity",
          label: "EF",
          thAttr: { id: "done_interview_quantity_" + this.franchiseName },
          thClass: ["bg-info"],
          sortable: true,
        },
        {
          key: "interview_contract_quantity",
          label: "EP",
          thAttr: { id: "interview_contract_quantity_" + this.franchiseName },
          thClass: ["bg-info", "bg-lighten-3"],
          sortable: true,
        },
        {
          key: "scheduled_negotiation_quantity",
          label: "NEA",
          thAttr: {
            id: "scheduled_negotiation_quantity_" + this.franchiseName,
          },
          thClass: ["bg-info"],
          sortable: true,
        },
        {
          key: "negotiation_positive_feedbacks_quantity",
          label: "NEP",
          thAttr: {
            id: "negotiation_positive_feedbacks_quantity_" + this.franchiseName,
          },
          thClass: ["header-spacing", "bg-info", "bg-lighten-3"],
          sortable: true,
        },
        {
          key: "consultant_leads_quantity",
          label: "REC PN",
          thAttr: { id: "consultant_leads_quantity_" + this.franchiseName },
          thClass: ["bg-danger", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "client_leads_quantity",
          label: "REC CL",
          thAttr: { id: "client_leads_quantity_" + this.franchiseName },
          thClass: ["bg-danger", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "rec_ef",
          label: "REC/EF",
          thAttr: { id: "rec_ef_" + this.franchiseName },
          thClass: ["header-spacing", "bg-danger", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "ea_ef",
          label: "EA/EF",
          thAttr: { id: "ea_ef_" + this.franchiseName },
          thClass: ["bg-green-2", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "ef_ep",
          label: "EF/EP",
          thAttr: { id: "ef_ep_" + this.franchiseName },
          thClass: ["bg-green-2", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "ea_ep",
          label: "EA/EP",
          thAttr: { id: "ea_ep_" + this.franchiseName },
          thClass: ["bg-green-2", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "client_interview_contract_average",
          label: "R$/EP",
          thAttr: {
            id: "client_interview_contract_average_" + this.franchiseName,
          },
          thClass: ["header-spacing", "bg-green-2", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "scheduled_x_moment_quantity",
          label: "MXA",
          thAttr: { id: "scheduled_x_moment_quantity_" + this.franchiseName },
          thClass: ["bg-blue", "bg-lighten-2", "text-white"],
          sortable: true,
        },
        {
          key: "done_x_moment_quantity",
          label: "MXF",
          thAttr: { id: "done_x_moment_quantity_" + this.franchiseName },
          thClass: ["bg-blue", "bg-lighten-5", "text-white"],
          sortable: true,
        },
        {
          key: "x_moment_positive_feedbacks_quantity",
          label: "MXC",
          thAttr: {
            id: "x_moment_positive_feedbacks_quantity_" + this.franchiseName,
          },
          thClass: ["column-spacing", "bg-blue", "bg-lighten-2", "text-white"],
          sortable: true,
        },
        {
          key: "mxa_mxf",
          label: "MXA/MXF",
          thAttr: { id: "mxa_mxf_" + this.franchiseName },
          thClass: ["bg-blue", "bg-lighten-5", "text-white"],
          sortable: true,
        },
        {
          key: "mxf_mxc",
          label: "MXF/MXC",
          thAttr: { id: "mxf_mxc_" + this.franchiseName },
          thClass: ["header-spacing", "bg-blue", "bg-lighten-2", "text-white"],
          sortable: true,
        },
        {
          key: "scheduled_partnership_quantity",
          label: "PA",
          thAttr: {
            id: "scheduled_partnership_quantity_" + this.franchiseName,
          },
          thClass: ["bg-primary", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "done_partnership_quantity",
          label: "PF",
          thAttr: { id: "done_partnership_quantity_" + this.franchiseName },
          thClass: ["bg-primary", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "partnership_positive_feedbacks_quantity",
          label: "PC",
          thAttr: {
            id: "partnership_positive_feedbacks_quantity_" + this.franchiseName,
          },
          thClass: ["column-spacing", "bg-primary", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "pa_pf",
          label: "PA/PF",
          thAttr: { id: "pa_pf_" + this.franchiseName },
          thClass: ["bg-primary", "bg-lighten-5"],
          sortable: true,
        },
        {
          key: "pf_pc",
          label: "PF/PC",
          thAttr: { id: "pf_pc_" + this.franchiseName },
          thClass: ["header-spacing", "bg-primary", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "support_contract_quantity",
          label: "Suporte",
          thAttr: { id: "support_contract_quantity_" + this.franchiseName },
          thClass: ["bg-secondary", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "signature_contract_quantity",
          label: "Acompanhamentos",
          thAttr: { id: "signature_contract_quantity_" + this.franchiseName },
          thClass: ["bg-secondary", "bg-lighten-5", "header-spacing"],
          sortable: true,
        },
        {
          key: "renewal_contract_quantity",
          label: "Renovações",
          thAttr: { id: "renewal_contract_quantity_" + this.franchiseName },
          thClass: ["header-spacing", "bg-secondary", "bg-lighten-2"],
          sortable: true,
        },
        {
          key: "interview_contract_amount",
          label: "R$ de EP",
          thAttr: { id: "interview_contract_amount_" + this.franchiseName },
          thClass: ["bg-success", "bg-lighten-1"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "subscription_contract_amount",
          label: "R$ de Assinatura",
          thAttr: { id: "subscription_contract_amount_" + this.franchiseName },
          thClass: ["bg-success", "bg-lighten-4"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "other_financial_solutions_contract_amount",
          label: "R$ de Soluções",
          thAttr: {
            id:
              "other_financial_solutions_contract_amount_" + this.franchiseName,
          },
          thClass: ["bg-success", "bg-lighten-1"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "total_contract_amount",
          label: "R$ Total",
          thAttr: { id: "total_contract_amount_" + this.franchiseName },
          thClass: ["bg-success", "bg-lighten-4"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
        {
          key: "ltv_cl",
          label: "LTV/CL",
          thAttr: { id: "ltv_cl_" + this.franchiseName },
          thClass: ["bg-success", "bg-lighten-1"],
          tdClass: ["pl-0", "pr-4"],
          sortable: true,
        },
      ];
      this.items.push(this.total);
    },
    rowClass(item, type) {
      let colorClass = "";
      if (!item || type !== "row") {
        return;
      }
      // eslint-disable-next-line consistent-return
      if (item.name === "Total") {
        if (this.skin === "dark") {
          colorClass = "total-dark-color";
        } else if (this.skin === "light") {
          colorClass = "table-primary";
        }
        return colorClass;
      }
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.sortField = null;
      } else {
        this.sortField = event.sortBy;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.popover.b-popover {
  max-width: 500px !important;
  width: max-content !important;
}

::v-deep .table {
  thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 3 !important;
  }
}

.card .table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
}
</style>
<style lang="scss">
.header-spacing {
  border-right: 15px solid #ebe9f1;
}

.column-spacing {
  border-right: 10px solid #ebe9f1;
}

th {
  text-align: center;
}

[aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Earrows%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-257.000000, -561.000000)' stroke='%236e6b7b' stroke-width='2'%3E%3Cg id='arrows' transform='translate(258.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Eactive-up%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-197.000000, -561.000000)' stroke-width='2'%3E%3Cg id='active-up' transform='translate(198.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)' stroke='%236e6b7b'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

[aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='26px' viewBox='0 0 14 26' version='1.1'%3E%3Ctitle%3Eactive-up%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Artboard' transform='translate(-197.000000, -561.000000)' stroke-width='2'%3E%3Cg id='active-up' transform='translate(198.000000, 562.000000)'%3E%3Cg id='chevron-down' transform='translate(0.000000, 18.000000)' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='0 0 6 6 12 0'/%3E%3C/g%3E%3Cg id='chevron-up' stroke='%236e6b7b'%3E%3Cpolyline id='Path' points='12 6 6 0 0 6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

.total-dark-color {
  background-color: #ff7f50 !important;

  &:hover {
    background-color: rgba(255, 127, 80, 0.8) !important;
  }
}

.dark-layout .table tr:last-child {
  td {
    color: #fff !important;
  }
}

.scroll-fixed {
  overflow-x: scroll !important;
}
</style>
