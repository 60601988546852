
function sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
  if(aRow['leader_franchise_name'] === 'Total' 
    || (aRow['name'] === 'Total' && aRow['visible'] === undefined) 
  ){
    return 0;
  }
  const a = aRow[key]
  const b = bRow[key]
  if (
    (typeof a === 'number' && typeof b === 'number') ||
    (a instanceof Date && b instanceof Date)
  ) {
    // If both compared fields are native numbers or both are native dates
    return a < b ? -1 : a > b ? 1 : 0
  } else {
    // Otherwise stringify the field data and use String.prototype.localeCompare
    return toString(a).localeCompare(toString(b), compareLocale, compareOptions)
  }
}
  
// Helper function to stringify the values of an Object
function toString(value) {
  if (value === null || typeof value === 'undefined') {
    return ''
  } else if (value instanceof Object) {
    return Object.keys(value)
      .sort()
      .map(key => toString(value[key]))
      .join(' ')
  } else {
    return String(value)
  }
}

export default sortCompare;