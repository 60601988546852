<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="6" cols="12">
            <b-form-group label="Período" label-for="searchReportRange">
              <flat-pickr
                id="searchReportRange"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                placeholder="Informe um período para consulta do KPI"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" cols="12">
            <b-form-group label="PN" label-for="searchConsultant">
              <v-select
                id="searchConsultant"
                v-model="consultant"
                :options="consultants"
                :loading="loading_consultants"
                :close-on-select="false"
                label="name"
                multiple
              >
                <template #no-options="{ loading }"> Sem resultados </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-end mt-1">
          <b-col md="6" cols="12">
            <b-form-group label="Valores" label-for="searchContractStatus">
              <b-form-radio-group
                id="searchContractStatus"
                v-model="contractStatus"
              >
                <b-form-radio value="gross">Brutos</b-form-radio>
                <b-form-radio value="valid">Validados</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="6" cols="12" class="text-right">
            <b-row class="align-items-center justify-content-end">
              <b-col cols="auto">
                <!--
                <b-form-checkbox
                  class="mr-1 switch-mt"
                  v-model="allConsultants"
                  switch
                >
                  Incluir consultores excluídos
                </b-form-checkbox>
                -->
              </b-col>
              <b-col>
                <b-button
                  variant="outline-primary"
                  @click.prevent="search"
                  :disabled="loading"
                >
                  <b-spinner v-if="loading" small />
                  <feather-icon v-else icon="SearchIcon" class="mr-50" />
                  <span>
                    {{ loading ? "Aguarde..." : "Pesquisar" }}
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <template v-if="totalizers.length > 0">
      <kpi-totalizers
        :totalizers="totalizers"
        @changeVisibleFranchise="changeFranchiseVisibility"
        @rowClicked="changeFocus"
      />
    </template>

    <template v-for="(franchise, leader_franchise_name) in franchises">
      <kpi-table
        v-if="getFranchiseVisibility(leader_franchise_name)"
        :franchiseData="franchise"
        :franchiseName="leader_franchise_name"
        :ref="leader_franchise_name"
        :total="getFranchiseTotal(leader_franchise_name)"
      />
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import flatPickr from "vue-flatpickr-component";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import KpiTable from "../components/KpiTable";
import KpiTotalizers from "../components/KpiTotalizers";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    vSelect,
    flatPickr,
    KpiTable,
    KpiTotalizers,
    KpiTable,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      loading_consultants: false,
      flatPickrConfig: {
        altFormat: "d/m/Y",
        altInput: true,
        mode: "range",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      consultant: [],
      rangeDate: undefined,
      franchises: {},
      franchiseVisibilities: [],
      contractStatus: "gross",
      allConsultants: false,
    };
  },
  computed: {
    ...mapGetters({
      kpiList: types.KPI_LIST,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      consultants: types.CONSULTANTS,
    }),
    totalizers: function () {
      return _(this.kpiList)
        .groupBy("leader_franchise_name")
        .map((objs, key) => ({
          leader_franchise_name: key,
          consultants_quantity: objs.length,
          name: "Total",
          hot_leads_quantity: _.sumBy(objs, "hot_leads_quantity"),
          scheduled_interview_quantity: _.sumBy(
            objs,
            "scheduled_interview_quantity"
          ),
          pre_interviews_quantity: _.sumBy(objs, "pre_interviews_quantity"),
          interview_contract_quantity: _.sumBy(
            objs,
            "interview_contract_quantity"
          ),
          negotiation_interview_contract_quantity: _.sumBy(
            objs,
            "negotiation_interview_contract_quantity"
          ),
          done_interview_quantity: _.sumBy(objs, "done_interview_quantity"),
          client_leads_quantity: _.sumBy(objs, "client_leads_quantity"),
          consultant_leads_quantity: _.sumBy(objs, "consultant_leads_quantity"),
          scheduled_x_moment_quantity: _.sumBy(
            objs,
            "scheduled_x_moment_quantity"
          ),
          done_x_moment_quantity: _.sumBy(objs, "done_x_moment_quantity"),
          x_moment_positive_feedbacks_quantity: _.sumBy(
            objs,
            "x_moment_positive_feedbacks_quantity"
          ),
          scheduled_partnership_quantity: _.sumBy(
            objs,
            "scheduled_partnership_quantity"
          ),
          done_partnership_quantity: _.sumBy(objs, "done_partnership_quantity"),
          partnership_positive_feedbacks_quantity: _.sumBy(
            objs,
            "partnership_positive_feedbacks_quantity"
          ),
          interview_contract_amount: _.sumBy(objs, "interview_contract_amount"),
          subscription_contract_amount: _.sumBy(
            objs,
            "subscription_contract_amount"
          ),
          other_financial_solutions_contract_amount: _.sumBy(
            objs,
            "other_financial_solutions_contract_amount"
          ),
          total_contract_amount: _.sumBy(objs, "total_contract_amount"),
          signature_contract_quantity: _.sumBy(
            objs,
            "signature_contract_quantity"
          ),
          support_contract_quantity: _.sumBy(objs, "support_contract_quantity"),
          scheduled_negotiation_quantity: _.sumBy(
            objs,
            "scheduled_negotiation_quantity"
          ),
          negotiation_positive_feedbacks_quantity: _.sumBy(
            objs,
            "negotiation_positive_feedbacks_quantity"
          ),
          renewal_contract_quantity: _.sumBy(objs, "renewal_contract_quantity"),
        }))
        .value();
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )
      .toISOString()
      .split("T")[0];
    const currentDayOfMonth = currentDate.toISOString().split("T")[0];
    this.rangeDate = `${firstDayOfMonth} até ${currentDayOfMonth}`;
    this.loading_consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading_consultants = false;
      });
    this.search();
  },
  methods: {
    ...mapActions({
      getkpiList: types.GET_CONSULTANT_AND_CHILDS,
      getConsultants: types.GET_CONSULTANTS,
    }),
    search() {
      this.loading = true;
      var startDate = undefined;
      var endDate = undefined;
      if (this.rangeDate) {
        const dates = this.rangeDate.split(" até ");
        startDate = dates[0];
        endDate = dates[1];
      }
      const payload = {
        startDate,
        endDate,
        kpiContractStatus: this.contractStatus,
      };
      if (this.allConsultants) {
        payload.allConsultants = true;
      }
      this.getkpiList(payload)
        .then((response) => {
          let filteredKpiList = this.getFilteredKpiList();
          this.franchises = _.groupBy(
            filteredKpiList,
            (item) => item.leader_franchise_name
          );
          this.franchiseVisibilities = _.map(
            Object.keys(this.franchises),
            (franchiseName) => ({ name: franchiseName, visible: true })
          );
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar os dados de KPI. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFilteredKpiList() {
      let filteredKpiList = [];
      if (this.consultant.length) {
        _.forEach(this.consultant, (item) => {
          filteredKpiList = _.concat(
            filteredKpiList,
            _.filter(this.kpiList, { id: item.id })
          );
        });
      } else {
        filteredKpiList = this.kpiList;
      }
      return filteredKpiList;
    },
    changeFocus(item) {
      if (item.leader_franchise_name !== "Total") {
        const index = _.findIndex(this.franchiseVisibilities, {
          name: item.leader_franchise_name,
        });
        if (this.franchiseVisibilities[index]?.visible) {
          var ref = this.$refs[item.leader_franchise_name][0];
          ref.visible = true;
          ref.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    },
    getFranchiseTotal(leader_franchise_name) {
      if (this.consultant.length) {
        let filteredTotalizer = this.filteredTotalizers(
          this.getFilteredKpiList()
        );
        let totalizer = _.find(filteredTotalizer, { leader_franchise_name });
        if (totalizer == undefined) {
          return {};
        }
        return totalizer;
      }
      return _.find(this.totalizers, { leader_franchise_name });
    },
    changeFranchiseVisibility(payload) {
      const index = _.findIndex(this.franchiseVisibilities, {
        name: payload.name,
      });
      if (
        payload == undefined ||
        this.franchiseVisibilities[index]?.visible == undefined
      ) {
        return;
      }
      if (payload.name !== "Total") {
        this.franchiseVisibilities[index].visible = payload.visible;
      }
    },
    getFranchiseVisibility(name) {
      const index = _.findIndex(this.franchiseVisibilities, { name });
      return this.franchiseVisibilities[index]?.visible;
    },
    filteredTotalizers(filteredKpiList) {
      return _(filteredKpiList)
        .groupBy("leader_franchise_name")
        .map((objs, key) => ({
          leader_franchise_name: key,
          name: "Total",
          hot_leads_quantity: _.sumBy(objs, "hot_leads_quantity"),
          scheduled_interview_quantity: _.sumBy(
            objs,
            "scheduled_interview_quantity"
          ),
          pre_interviews_quantity: _.sumBy(objs, "pre_interviews_quantity"),
          interview_contract_quantity: _.sumBy(
            objs,
            "interview_contract_quantity"
          ),
          negotiation_interview_contract_quantity: _.sumBy(
            objs,
            "negotiation_interview_contract_quantity"
          ),
          done_interview_quantity: _.sumBy(objs, "done_interview_quantity"),
          client_leads_quantity: _.sumBy(objs, "client_leads_quantity"),
          consultant_leads_quantity: _.sumBy(objs, "consultant_leads_quantity"),
          scheduled_x_moment_quantity: _.sumBy(
            objs,
            "scheduled_x_moment_quantity"
          ),
          done_x_moment_quantity: _.sumBy(objs, "done_x_moment_quantity"),
          x_moment_positive_feedbacks_quantity: _.sumBy(
            objs,
            "x_moment_positive_feedbacks_quantity"
          ),
          scheduled_partnership_quantity: _.sumBy(
            objs,
            "scheduled_partnership_quantity"
          ),
          done_partnership_quantity: _.sumBy(objs, "done_partnership_quantity"),
          partnership_positive_feedbacks_quantity: _.sumBy(
            objs,
            "partnership_positive_feedbacks_quantity"
          ),
          interview_contract_amount: _.sumBy(objs, "interview_contract_amount"),
          subscription_contract_amount: _.sumBy(
            objs,
            "subscription_contract_amount"
          ),
          other_financial_solutions_contract_amount: _.sumBy(
            objs,
            "other_financial_solutions_contract_amount"
          ),
          total_contract_amount: _.sumBy(objs, "total_contract_amount"),
          signature_contract_quantity: _.sumBy(
            objs,
            "signature_contract_quantity"
          ),
          support_contract_quantity: _.sumBy(objs, "support_contract_quantity"),
          scheduled_negotiation_quantity: _.sumBy(
            objs,
            "scheduled_negotiation_quantity"
          ),
          negotiation_positive_feedbacks_quantity: _.sumBy(
            objs,
            "negotiation_positive_feedbacks_quantity"
          ),
          renewal_contract_quantity: _.sumBy(objs, "renewal_contract_quantity"),
        }))
        .value();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.switch-mt {
  margin-top: 0.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
